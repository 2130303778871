.root {
    padding-top: 160px;
    padding-bottom: 80px;
}
.description {
    max-width: 500px;
    margin: auto;
    margin-top: var(--mantine-spacing-xl);
    margin-bottom: calc(1.5 * var(--mantine-spacing-xl));
}

.title {
    font-family: Greycliff CF,
    var(--mantine-font-family);
    text-align: center;
    font-weight: 900;
    font-size: 38px;

    @media (max-width: var(--mantine-breakpoint-sm)) {
        font-size: 32px;
    }
}