.sectionWrapper {
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.sectionContent {
  flex-grow: 1;
  overflow-y: scroll;
}

.wideView {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: absolute;

  top: calc(var(--app-shell-header-offset, 0px) + var(--app-shell-padding, 0px));
  left: calc(var(--app-shell-navbar-offset, 0px) + var(--app-shell-padding, 0px));
  right: var(--app-shell-padding, 0px);
  bottom: var(--app-shell-padding, 0px);
}

.narrowView {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: calc(var(--app-shell-header-offset, 0px) + var(--app-shell-padding, 0px));
  left: calc(var(--app-shell-navbar-offset, 0px) + var(--app-shell-padding, 0px));
  right: var(--app-shell-padding, 0px);
  bottom: var(--app-shell-padding, 0px);
}